<template>
    <painel titulo="Templates" icone="pi pi-copy" :refreshFunction="obterTemplate">
        <div class="mb-4">
            <detalhe titulo="Código">{{ template?.codigo }}</detalhe>
            <detalhe titulo="Descrição">{{ template?.descricao }}</detalhe>
            <detalhe titulo="Dados">{{ template?.dados }}</detalhe>
            <detalhe titulo="Arquivo" v-if="temArquivo">{{ template?.nomeArquivo }}<btn-download :template="template"></btn-download></detalhe>
        </div>
        <btn-atualizar :template="template"></btn-atualizar>
        <btn-inserir class="ml-2"></btn-inserir>
        <btn-voltar
            :route="{
                name: `Templates`,
            }"
        ></btn-voltar>
    </painel>
</template>

<script>
import TemplatesService from './services';
import BtnInserir from './BtnInserir';
import BtnAtualizar from './BtnAtualizar';
import BtnDownload from './BtnDownload';

export default {
    components: {
        BtnInserir,
        BtnAtualizar,
        BtnDownload,
    },

    data() {
        return {
            template: null,
        };
    },

    computed: {
        temArquivo() {
            return this.template?.nomeArquivo;
        },
    },

    methods: {
        obterTemplate() {
            this.$store.dispatch('addRequest');
            TemplatesService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.template = response.data;
                } else {
                    this.template = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterTemplate();
    },
};
</script>
